import React, { useContext } from 'react';
import { Container, Icon ,Grid} from 'semantic-ui-react';
import SocialMediaIcons from './SocialMediaIcons';
import { ThemeContext } from '../contexts/ThemeContext'; 

const Footer = () => {
  // Access theme colors from the ThemeContext
  const { themeColors } = useContext(ThemeContext);

  return (
    <div style={{ backgroundColor: themeColors.headerFooterBackground,
				  color: themeColors.textColor, padding: '2rem 0', 
				  width: '100%',}}>
      <Container fluid>
		<Grid stackable columns={3} textAlign="center">
			
			{/* Footer Text and Social Media Icons */}

			{/* Contact Information */}
			<Grid.Column>
				<p><strong>< Icon name="address card"/ >Contact Information:</strong></p>
				<p><strong>< Icon name="building"/ >Address</strong> 270 Nantucket Pl Newport News Va 23606</p>
                <p><strong>
					Phone:</strong>< Icon name="phone square"/ > <a href="tel:+757-7150427" style={{ color: themeColors.textColor }}>+1 (757) 7150427</a>
				</p>
				<p>
				<strong>	Email:</strong>< Icon name="envelope open"/ > <a href="carterpaul1@yahoo.com" style={{ color: themeColors.textColor }}>carterpaul1@yahoo.com</a>
				</p>
				<SocialMediaIcons />
			</Grid.Column>
		</Grid>
      </Container>
    </div>
  );
};

export default Footer;
