import React from 'react';
import { Icon } from 'semantic-ui-react';

const SocialMediaIcons = () => {
  return (
    <div style={{ display: 'flex', justifyContent: 'center', margin: '20px 0' }}>
      <a href="https://www.linkedin.com/in/paul-carter-2334b356/" target="_blank" rel="noopener noreferrer" style={{ margin: '0 10px' }}>
        <Icon name="linkedin" size="huge" />
      </a>
      <a href="https://github.com/carterpaul1" target="_blank" rel="noopener noreferrer" style={{ margin: '0 10px' }}>
        <Icon name="github" size="huge" />
      </a>
    </div>
  );
};

export default SocialMediaIcons;

