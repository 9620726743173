import React, { Suspense, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import { ThemeProvider } from './contexts/ThemeContext';
import 'slick-carousel/slick/slick.css';
import './styles/theme.css';
import './styles/slider.css';
import { Analytics } from "@vercel/analytics/react"

// Lazy load the page components
const LandingPage = React.lazy(() => import('./components/LandingPage'));
const About = React.lazy(() => import('./components/pages/About'));
const Services = React.lazy(() => import('./components/pages/Services'));
const Contact = React.lazy(() => import('./components/pages/Contact'));
const Portfolio = React.lazy(() => import('./components/pages/Portfolio'));

// Component to handle dynamic title updates
const DynamicTitle = ({ title }) => {
  useEffect(() => {
    document.title = title || "Paul Carter's Portfolio";
  }, [title]);

  return null;
};

// Application Entry Point
function App() {
  return (
    <ThemeProvider>
      <Router>
        <div>
          {/* Common Header */}
          <Header />

          {/* Main Content */}
          <main>
            <Suspense fallback={<div>Loading...</div>}>
              <Routes>
                {/* Define Routes */}
                <Route
                  path="/"
                  element={
                    <>
                      <DynamicTitle title="Home - Paul Carter's Portfolio" />
                      <LandingPage />
                    </>
                  }
                />
                <Route
                  path="/about"
                  element={
                    <>
                      <DynamicTitle title="About - Paul Carter's Portfolio" />
                      <About />
                    </>
                  }
                />
                <Route
                  path="/contact"
                  element={
                    <>
                      <DynamicTitle title="Contact - Paul Carter's Portfolio" />
                      <Contact />
                    </>
                  }
                />
                <Route
                  path="/services"
                  element={
                    <>
                      <DynamicTitle title="Services - Paul Carter's Portfolio" />
                      <Services />
                    </>
                  }
                />
                <Route
                  path="/portfolio"
                  element={
                    <>
                      <DynamicTitle title="Portfolio - Paul Carter's Portfolio" />
                      <Portfolio />
                    </>
                  }
                />
                {/* Fallback Route */}
                <Route
                  path="*"
                  element={
                    <>
                      <DynamicTitle title="404 - Page Not Found" />
                      <div style={{ textAlign: 'center', padding: '2rem' }}>
                        <h1>404</h1>
                        <p>Oops! The page you're looking for doesn't exist.</p>
                      </div>
                    </>
                  }
                />
              </Routes>
            </Suspense>
          </main>
<Analytics/>
          {/* Common Footer */}
          <Footer />
        </div>
      </Router>
    </ThemeProvider>
  );
}

export default App;

