import React, { useContext } from 'react';
import Nav from './Nav';
import SocialMediaIcons from './SocialMediaIcons'; 
import { ThemeContext } from '../contexts/ThemeContext';

const Header = () => {
  const { themeColors } = useContext(ThemeContext); // Access theme colors

  return (
    <div style={{ backgroundColor: themeColors.headerFooterBackground, padding: '1em' }}>
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <SocialMediaIcons />
      </div>
	  <Nav />
    </div>
  );
};

export default Header;

